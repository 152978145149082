<!-- eslint-disable no-console -->
<script>
import Layout from "../layouts/main";
import appConfig from "@/app.config";
import Stat from "@/components/widgets/widget-stat-v3";
import api from "../../common/axios";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import { sparklineChartData, salesDonutChart, radialBarChart } from "./data";

export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description, DatePicker }],
  },
  components: {
    Layout,
    Stat,
    DatePicker,
  },
  data() {
    return {
      config: {
        api: "role/",
        dashboarCount: "dashboard/counter",
        counterLc: "dashboard/get_penawaran_lc",
        counterTo: "dashboard/get_penawaran_to",
        counterTd: "dashboard/get_penawaran_driver",
        counterVehicle: "dashboard/get_total_vehicle",
        counterCategoryVehicle: "dashboard/get_vehicle_category",
        transactionSummary: "dashboard/get_transaction_summary",
      },
      menus: [],
      transactionType: "all",
      user: {},
      counterLc: {},
      counterTo: {},
      counterTd: {},
      counterVehicle: 0,

      totalChartOffer: 0,
      totalChartArmada: 0,
      totalChartIncome: 0,

      counterCategoryVehicle: {},
      dashboardCounter: {
        total_lc: 0,
        total_truck_owner: 0,
        total_driver: 0,
        total_job_finished: 0,
      },

      payloadDate: {
        startDate: moment().subtract(3, "months").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      sparklineChartData: sparklineChartData,
      salesDonutChart: salesDonutChart,
      radialBarChart: radialBarChart,
      statData: [],
      statArmada: [],
      ArmadaAll: [],
      transOffer: {
        chartOptionsOffer: {
          chart: {
            padding: {
              right: 0,
              left: 0,
            },
            stacked: false,
            toolbar: {
              show: false,
            },
          },
          stroke: {
            width: [0],
            curve: "smooth",
          },
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 4,
              columnWidth: "30%",
              distributed: true,
              dataLabels: {
                position: "top",
              },
            },
          },
          colors: ["#f01b3c"],
          fill: {
            opacity: [0.85, 0.25, 1],
            gradient: {
              inverseColors: false,
              shade: "light",
              type: "vertical",
              opacityFrom: 0.85,
              opacityTo: 0.55,
              stops: [0, 100, 100, 100],
            },
          },
          xaxis: {
            type: "datetime",
            categories: [],
          },
          yaxis: {
            title: {
              text: "Jumlah Pengiriman",
            },
          },
          tooltip: {
            y: {
              formatter(y) {
                if (typeof y !== "undefined") {
                  return y.toFixed(0);
                }
                return y;
              },
            },
          },
          grid: {
            borderColor: "#f1f3fa",
          },
        },
        seriesOffer: [
          {
            type: "column",
            name: "Jumlah Penawaran",
            data: [],
          },
        ],
      },

      transArmada: {
        chartOptionsArmada: {
          chart: {
            padding: {
              right: 0,
              left: 0,
            },
            stacked: false,
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 4,
              columnWidth: "30%",
              distributed: true,
              dataLabels: {
                position: "top",
              },
            },
          },
          stroke: {
            width: [0],
            curve: "smooth",
          },
          colors: ["#f01b3c"],
          fill: {
            opacity: [0.85, 0.25, 1],
            gradient: {
              inverseColors: false,
              shade: "light",
              type: "vertical",
              opacityFrom: 0.85,
              opacityTo: 0.55,
              stops: [0, 100, 100, 100],
            },
          },
          xaxis: {
            type: "datetime",
            categories: [],
          },
          yaxis: {
            title: {
              text: "Unit",
            },
          },
          tooltip: {
            y: {
              formatter(y) {
                if (typeof y !== "undefined") {
                  return y.toFixed(0);
                }
                return y;
              },
            },
          },
          grid: {
            borderColor: "#f1f3fa",
          },
        },
        seriesAramada: [
          {
            type: "column",
            name: "Unit Armada",
            data: [],
          },
        ],
      },

      transIncome: {
        chartOptionsIncome: {
          chart: {
            padding: {
              right: 0,
              left: 0,
            },
            stacked: false,
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 4,
              columnWidth: "30%",
              distributed: true,
              dataLabels: {
                position: "top",
              },
            },
          },
          stroke: {
            width: [0],
            curve: "smooth",
          },
          colors: ["#f01b3c"],
          fill: {
            opacity: [0.85, 0.25, 1],
            gradient: {
              inverseColors: false,
              shade: "light",
              type: "vertical",
              opacityFrom: 0.85,
              opacityTo: 0.55,
              stops: [0, 100, 100, 100],
            },
          },
          xaxis: {
            type: "datetime",
            categories: [],
          },
          yaxis: {
            title: {
              text: "Rupiah",
            },
          },
          tooltip: {
            y: {
              formatter(y) {
                if (typeof y !== "undefined") {
                  return y.toFixed(0);
                }
                return y;
              },
            },
          },
          grid: {
            borderColor: "#f1f3fa",
          },
        },
        seriesIncome: [
          {
            type: "column",
            name: "Total Rupiah",
            data: [],
          },
        ],
      },
    };
  },
  methods: {
    notBeforeToday(date) {
      let d = this.payloadDate.startDate;
      if (d) {
        return date < d;
      } else {
        return date < new Date(new Date().setHours(0, 0, 0, 0) - 1);
      }
    },

    notBeforeToday2(date) {
      return date < moment().subtract(4, "months");
    },

    getMenu() {
      let _ = this;
      api.get(_.config.api + this.user.role_id).then((response) => {
        let _ = this;
        _.menus = response.data.data;
        let permission_etruck = [];
        _.menus.menu_ids.forEach((element) => {
          Object.entries(element.permission).forEach((entry) => {
            // const [key, value] = entry;
            const [key] = entry;
            permission_etruck.push(element.menu.name + " " + key);
            // console.log(key, value);
          });
          // location.reload();
          this.$forceUpdate();
        });
        localStorage.setItem("permit", JSON.stringify(permission_etruck));
      });
    },

    getChartTransaction() {
      let _ = this;
      _.totalChartOffer = 0;
      _.totalChartArmada = 0;
      _.totalChartIncome = 0;
      api
        .get(_.config.transactionSummary, {
          params: {
            type: this.transactionType,
            start_date: this.payloadDate.startDate,
            end_date: this.payloadDate.endDate,
          },
        })
        .then((response) => {
          let seriesData = response.data.data;
          let data = [];
          let cat = [];
          let usedArmada = [];
          let totalIncome = [];
          seriesData.forEach((element) => {
            data.push(element.totalOffer);
            usedArmada.push(element.totalUsedVehicle);
            cat.push(element.month);

            if (this.transactionType == "logistic_company") {
              totalIncome.push(element.totalExpense);
              this.totalChartIncome =
                this.totalChartIncome + element.totalExpense;
            } else {
              totalIncome.push(element.totalIncome);
              this.totalChartIncome =
                this.totalChartIncome + element.totalIncome;
            }
            this.totalChartOffer = this.totalChartOffer + element.totalOffer;
            this.totalChartArmada =
              this.totalChartArmada + element.totalUsedVehicle;
          });

          this.$refs.transOffer.updateSeries([
            {
              data: data,
            },
          ]);

          this.$refs.transOffer.updateOptions({
            xaxis: {
              categories: cat,
            },
          });

          this.$refs.transArmada.updateSeries([
            {
              data: usedArmada,
            },
          ]);

          this.$refs.transArmada.updateOptions({
            xaxis: {
              categories: cat,
            },
          });

          this.$refs.transIncome.updateSeries([
            {
              data: totalIncome,
            },
          ]);

          this.$refs.transIncome.updateOptions({
            xaxis: {
              categories: cat,
            },
          });
          // console.log(series);
        });
    },

    getDashboard() {
      let _ = this;
      api.get(_.config.dashboarCount).then((response) => {
        let _ = this;
        _.dashboardCounter = response.data.data;
        this.statData.push(
          {
            title: "Logistic Company",
            image: require("@/assets/images/services-icon/1.png"),
            value: parseInt(this.dashboardCounter.total_lc),
          },
          {
            title: "Truck Owner",
            image: require("@/assets/images/services-icon/2.png"),
            value: parseInt(this.dashboardCounter.total_truck_owner),
          },
          {
            title: "Truck Driver",
            image: require("@/assets/images/services-icon/3.png"),
            value: parseInt(this.dashboardCounter.total_driver),
          }
        );
      });
    },

    async filtering() {
      this.payloadDate.startDate = moment(this.payloadDate.startDate).format(
        "YYYY-MM-DD"
      );
      this.payloadDate.endDate = moment(this.payloadDate.endDate).format(
        "YYYY-MM-DD"
      );
      await this.getChartTransaction();
    },

    async filterButton(param) {
      this.transactionType = param;
      await this.getChartTransaction();
    },

    async resetFilter() {
      this.payloadDate.startDate = moment()
        .subtract(3, "months")
        .format("YYYY-MM-DD");
      this.payloadDate.endDate = moment().format("YYYY-MM-DD");
      this.transactionType = "all";
      await this.getChartTransaction();
    },

    getCounterLc() {
      let _ = this;
      api.get(_.config.counterLc).then((response) => {
        let _ = this;
        _.counterLc = response.data.data;
      });
    },

    getCounterTo() {
      let _ = this;
      api.get(_.config.counterTo).then((response) => {
        let _ = this;
        _.counterTo = response.data.data;
      });
    },

    getCounterTd() {
      let _ = this;
      api.get(_.config.counterTd).then((response) => {
        let _ = this;
        _.counterTd = response.data.data;
      });
    },

    getCounterVehicle() {
      let _ = this;
      api.get(_.config.counterVehicle).then((response) => {
        let _ = this;
        _.counterVehicle = response.data.data;
        this.statArmada.push({
          title: "Total Seluruh Armada",
          image: require("@/assets/images/services-icon/armada.png"),
          value: parseInt(this.counterVehicle),
        });
      });
    },

    getCounterCategoryVehicle() {
      let _ = this;
      api.get(_.config.counterCategoryVehicle).then((response) => {
        let _ = this;
        _.counterCategoryVehicle = response.data.data;
        _.counterCategoryVehicle.forEach((element) => {
          this.ArmadaAll.push({
            title: element.name,
            image: element.photo,
            value: element.total_vehicle,
          });
        });
      });
    },
  },

  async mounted() {
    await this.getDashboard();
    await this.getChartTransaction();
    await this.getCounterLc();
    await this.getCounterTo();
    await this.getCounterTd();
    await this.getCounterVehicle();
    await this.getCounterCategoryVehicle();

    this.user = this.$store.state.auth.currentUser.data;
    if (!localStorage.getItem("permit")) {
      // this.getMenu();
    }
  },
};
</script>

<template>
  <Layout>
    <!-- start page title -->
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h3>{{ $t("dashboard.title") }}</h3>
          <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item active">
              {{ $t("dashboard.subTitle") }}
            </li>
          </ol>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row mb-3">
      <div class="col-xl-12">
        <div class="page-title-box pb-0">
          <h3 class="font-size-18">Total User</h3>
        </div>
      </div>
      <div class="col-xl-4 col-md-6" v-for="stat of statData" :key="stat.icon">
        <Stat :title="stat.title" :image="stat.image" :value="stat.value" />
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-6">
        <div class="page-title-box p-0">
          <h4 class="font-size-18">Value Penawaran LC</h4>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="card bg-dash-1">
              <div class="card-body">
                <div
                  class="d-flex justify-content-start align-items-center row"
                >
                  <div class="col-md-3">
                    <img
                      src="@/assets/images/services-icon/wr-1.png"
                      alt
                      width="42.18px"
                      height="42"
                    />
                  </div>
                  <div class="col-md-9">
                    <h5
                      class="text-capitalize font-size-14 text-left text-dash-string pt-2 font-weight-bolder"
                    >
                      Total Value Penawaran
                    </h5>
                  </div>
                  <div class="col-md-12">
                    <h5
                      class="text-capitalize font-size-24 text-dash-number ml-1 pt-3 mb-0 font-weight-bolder"
                    >
                      {{ counterLc.totalOffer | currency_3 }}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 width-dash">
            <div class="row">
              <div class="col-md-12 card bg-white height-dash">
                <div class="row">
                  <div class="card-body card-body-pad">
                    <div class="d-flex justify-content-start row">
                      <div class="col-md-2">
                        <img
                          src="@/assets/images/services-icon/wr-001.png"
                          alt
                          width="32.18px"
                          height="32"
                        />
                      </div>
                      <div class="col-md-4">
                        <h5
                          class="text-capitalize font-size-14 text-left text-dash-string pt-2 font-weight-bolder"
                        >
                          Deal
                        </h5>
                      </div>

                      <div class="col-md-6">
                        <h5
                          class="text-capitalize font-size-14 text-dash-number pt-2 font-weight-bolder"
                        >
                          {{ counterLc.totalOfferFinishedJob | currency_3 }}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 card bg-white height-dash">
                <div class="row">
                  <div class="card-body card-body-pad">
                    <div class="d-flex justify-content-start row">
                      <div class="col-md-2">
                        <img
                          src="@/assets/images/services-icon/wr-002.png"
                          alt
                          width="32.18px"
                          height="32"
                        />
                      </div>
                      <div class="col-md-4">
                        <h5
                          class="text-capitalize font-size-14 text-left text-dash-string pt-2 font-weight-bolder"
                        >
                          Tidak Deal
                        </h5>
                      </div>

                      <div class="col-md-6">
                        <h5
                          class="text-capitalize font-size-14 text-dash-number pt-2 font-weight-bolder"
                        >
                          {{ counterLc.totalOfferCanceledJob | currency_3 }}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="page-title-box p-0">
          <h4 class="font-size-18">Penawaran LC</h4>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <div class="card-body">
                <div
                  class="d-flex justify-content-start align-items-center row"
                >
                  <div class="col-md-3">
                    <img
                      src="@/assets/images/services-icon/wr-0001.png"
                      alt
                      width="42.18px"
                      height="42"
                    />
                  </div>
                  <div class="col-md-9">
                    <h5
                      class="text-capitalize font-size-14 text-left text-dash-string pt-2 font-weight-bolder"
                    >
                      Total Penawaran
                    </h5>
                  </div>
                  <div class="col-md-12">
                    <h5
                      class="text-capitalize font-size-24 text-dash-number ml-1 pt-3 mb-0 font-weight-bolder"
                    >
                      {{ counterLc.countOffer }}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 width-dash">
            <div class="row">
              <div class="col-md-12 card bg-white height-dash">
                <div class="row">
                  <div class="card-body card-body-pad">
                    <div class="d-flex justify-content-start row">
                      <div class="col-md-2">
                        <img
                          src="@/assets/images/services-icon/wr-0001.png"
                          alt
                          width="32.18px"
                          height="32"
                        />
                      </div>
                      <div class="col-md-6">
                        <h5
                          class="text-capitalize font-size-14 text-left text-dash-string pt-2 font-weight-bolder"
                        >
                          Deal
                        </h5>
                      </div>

                      <div class="col-md-4">
                        <h5
                          class="text-capitalize text-right font-size-14 text-dash-number pt-2 font-weight-bolder"
                        >
                          {{ counterLc.countOfferFinishedJob }}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 card bg-white height-dash">
                <div class="row">
                  <div class="card-body card-body-pad">
                    <div class="d-flex justify-content-start row">
                      <div class="col-md-2">
                        <img
                          src="@/assets/images/services-icon/wr-002.png"
                          alt
                          width="32.18px"
                          height="32"
                        />
                      </div>
                      <div class="col-md-6">
                        <h5
                          class="text-capitalize font-size-14 text-left text-dash-string pt-2 font-weight-bolder"
                        >
                          Tidak Deal
                        </h5>
                      </div>

                      <div class="col-md-4">
                        <h5
                          class="text-capitalize text-right font-size-14 text-dash-number pt-2 font-weight-bolder"
                        >
                          {{ counterLc.countOfferCanceledJob }}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-6">
        <div class="page-title-box p-0">
          <h4 class="font-size-18">Value Penawaran Truck Owner</h4>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="card bg-dash-2">
              <div class="card-body">
                <div
                  class="d-flex justify-content-start align-items-center row"
                >
                  <div class="col-md-3">
                    <img
                      src="@/assets/images/services-icon/wr-2.png"
                      alt
                      width="42.18px"
                      height="42"
                    />
                  </div>
                  <div class="col-md-9">
                    <h5
                      class="text-capitalize font-size-14 text-left text-dash-string pt-2 font-weight-bolder"
                    >
                      Total Value Penawaran
                    </h5>
                  </div>
                  <div class="col-md-12">
                    <h5
                      class="text-capitalize font-size-24 text-dash-number ml-1 pt-3 mb-0 font-weight-bolder"
                    >
                      {{ counterTo.totalOffer | currency_3 }}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 width-dash">
            <div class="row">
              <div class="col-md-12 card bg-white height-dash">
                <div class="row">
                  <div class="card-body card-body-pad">
                    <div class="d-flex justify-content-start row">
                      <div class="col-md-2">
                        <img
                          src="@/assets/images/services-icon/wr-001.png"
                          alt
                          width="32.18px"
                          height="32"
                        />
                      </div>
                      <div class="col-md-4">
                        <h5
                          class="text-capitalize font-size-14 text-left text-dash-string pt-2 font-weight-bolder"
                        >
                          Deal
                        </h5>
                      </div>

                      <div class="col-md-6">
                        <h5
                          class="text-capitalize font-size-14 text-dash-number pt-2 font-weight-bolder"
                        >
                          {{ counterTo.totalOfferFinishedJob | currency_3 }}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 card bg-white height-dash">
                <div class="row">
                  <div class="card-body card-body-pad">
                    <div class="d-flex justify-content-start row">
                      <div class="col-md-2">
                        <img
                          src="@/assets/images/services-icon/wr-002.png"
                          alt
                          width="32.18px"
                          height="32"
                        />
                      </div>
                      <div class="col-md-4">
                        <h5
                          class="text-capitalize font-size-14 text-left text-dash-string pt-2 font-weight-bolder"
                        >
                          Tidak Deal
                        </h5>
                      </div>

                      <div class="col-md-6">
                        <h5
                          class="text-capitalize font-size-14 text-dash-number pt-2 font-weight-bolder"
                        >
                          {{ counterTo.totalOfferCanceledJob | currency_3 }}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="page-title-box p-0">
          <h4 class="font-size-18">Penawaran Truck Owner</h4>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <div class="card-body">
                <div
                  class="d-flex justify-content-start align-items-center row"
                >
                  <div class="col-md-3">
                    <img
                      src="@/assets/images/services-icon/wr-0001.png"
                      alt
                      width="42.18px"
                      height="42"
                    />
                  </div>
                  <div class="col-md-9">
                    <h5
                      class="text-capitalize font-size-14 text-left text-dash-string pt-2 font-weight-bolder"
                    >
                      Total Penawaran
                    </h5>
                  </div>
                  <div class="col-md-12">
                    <h5
                      class="text-capitalize font-size-24 text-dash-number ml-1 pt-3 mb-0 font-weight-bolder"
                    >
                      {{ counterTo.countOffer }}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 width-dash">
            <div class="row">
              <div class="col-md-12 card bg-white height-dash">
                <div class="row">
                  <div class="card-body card-body-pad">
                    <div class="d-flex justify-content-start row">
                      <div class="col-md-2">
                        <img
                          src="@/assets/images/services-icon/wr-0001.png"
                          alt
                          width="32.18px"
                          height="32"
                        />
                      </div>
                      <div class="col-md-6">
                        <h5
                          class="text-capitalize font-size-14 text-left text-dash-string pt-2 font-weight-bolder"
                        >
                          Deal
                        </h5>
                      </div>

                      <div class="col-md-4">
                        <h5
                          class="text-capitalize text-right font-size-14 text-dash-number pt-2 font-weight-bolder"
                        >
                          {{ counterTo.countOfferFinishedJob }}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 card bg-white height-dash">
                <div class="row">
                  <div class="card-body card-body-pad">
                    <div class="d-flex justify-content-start row">
                      <div class="col-md-2">
                        <img
                          src="@/assets/images/services-icon/wr-0002.png"
                          alt
                          width="32.18px"
                          height="32"
                        />
                      </div>
                      <div class="col-md-6">
                        <h5
                          class="text-capitalize font-size-14 text-left text-dash-string pt-2 font-weight-bolder"
                        >
                          Tidak Deal
                        </h5>
                      </div>

                      <div class="col-md-4">
                        <h5
                          class="text-capitalize text-right font-size-14 text-dash-number pt-2 font-weight-bolder"
                        >
                          {{ counterTo.countOfferCanceledJob }}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-6">
        <div class="page-title-box p-0">
          <h4 class="font-size-18">Value Penawaran Truck Driver</h4>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="card bg-dash-3">
              <div class="card-body">
                <div
                  class="d-flex justify-content-start align-items-center row"
                >
                  <div class="col-md-3">
                    <img
                      src="@/assets/images/services-icon/wr-3.png"
                      alt
                      width="42.18px"
                      height="42"
                    />
                  </div>
                  <div class="col-md-9">
                    <h5
                      class="text-capitalize font-size-14 text-left text-dash-string pt-2 font-weight-bolder"
                    >
                      Total Value Penawaran
                    </h5>
                  </div>
                  <div class="col-md-12">
                    <h5
                      class="text-capitalize font-size-24 text-dash-number ml-1 pt-3 mb-0 font-weight-bolder"
                    >
                      {{ counterTd.totalOffer | currency_3 }}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 width-dash">
            <div class="row">
              <div class="col-md-12 card bg-white height-dash">
                <div class="row">
                  <div class="card-body card-body-pad">
                    <div class="d-flex justify-content-start row">
                      <div class="col-md-2">
                        <img
                          src="@/assets/images/services-icon/wr-001.png"
                          alt
                          width="32.18px"
                          height="32"
                        />
                      </div>
                      <div class="col-md-4">
                        <h5
                          class="text-capitalize font-size-14 text-left text-dash-string pt-2 font-weight-bolder"
                        >
                          Deal
                        </h5>
                      </div>

                      <div class="col-md-6">
                        <h5
                          class="text-capitalize font-size-14 text-dash-number pt-2 font-weight-bolder"
                        >
                          {{ counterTd.totalOfferFinishedJob | currency_3 }}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 card bg-white height-dash">
                <div class="row">
                  <div class="card-body card-body-pad">
                    <div class="d-flex justify-content-start row">
                      <div class="col-md-2">
                        <img
                          src="@/assets/images/services-icon/wr-002.png"
                          alt
                          width="32.18px"
                          height="32"
                        />
                      </div>
                      <div class="col-md-4">
                        <h5
                          class="text-capitalize font-size-14 text-left text-dash-string pt-2 font-weight-bolder"
                        >
                          Tidak Deal
                        </h5>
                      </div>

                      <div class="col-md-6">
                        <h5
                          class="text-capitalize font-size-14 text-dash-number pt-2 font-weight-bolder"
                        >
                          {{ counterTd.totalOfferCanceledJob | currency_3 }}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="page-title-box p-0">
          <h4 class="font-size-18">Penawaran Truk Driver</h4>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <div class="card-body">
                <div
                  class="d-flex justify-content-start align-items-center row"
                >
                  <div class="col-md-3">
                    <img
                      src="@/assets/images/services-icon/wr-0001.png"
                      alt
                      width="42.18px"
                      height="42"
                    />
                  </div>
                  <div class="col-md-9">
                    <h5
                      class="text-capitalize font-size-14 text-left text-dash-string pt-2 font-weight-bolder"
                    >
                      Total Penawaran
                    </h5>
                  </div>
                  <div class="col-md-12">
                    <h5
                      class="text-capitalize font-size-24 text-dash-number ml-1 pt-3 mb-0 font-weight-bolder"
                    >
                      {{ counterTd.countOffer }}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 width-dash">
            <div class="row">
              <div class="col-md-12 card bg-white height-dash">
                <div class="row">
                  <div class="card-body card-body-pad">
                    <div class="d-flex justify-content-start row">
                      <div class="col-md-2">
                        <img
                          src="@/assets/images/services-icon/wr-0001.png"
                          alt
                          width="32.18px"
                          height="32"
                        />
                      </div>
                      <div class="col-md-6">
                        <h5
                          class="text-capitalize font-size-14 text-left text-dash-string pt-2 font-weight-bolder"
                        >
                          Deal
                        </h5>
                      </div>

                      <div class="col-md-4">
                        <h5
                          class="text-capitalize text-right font-size-14 text-dash-number pt-2 font-weight-bolder"
                        >
                          {{ counterTd.countOfferFinishedJob }}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 card bg-white height-dash">
                <div class="row">
                  <div class="card-body card-body-pad">
                    <div class="d-flex justify-content-start row">
                      <div class="col-md-2">
                        <img
                          src="@/assets/images/services-icon/wr-0002.png"
                          alt
                          width="32.18px"
                          height="32"
                        />
                      </div>
                      <div class="col-md-6">
                        <h5
                          class="text-capitalize font-size-14 text-left text-dash-string pt-2 font-weight-bolder"
                        >
                          Tidak Deal
                        </h5>
                      </div>

                      <div class="col-md-4">
                        <h5
                          class="text-capitalize text-right font-size-14 text-dash-number pt-2 font-weight-bolder"
                        >
                          {{ counterTd.countOfferCanceledJob }}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-xl-12">
        <div class="page-title-box p-0">
          <h4 class="font-size-18">Total Armada</h4>
        </div>
      </div>
      <div class="col-xl-12">
        <div class="row">
          <div
            class="col-xl-4 col-md-6"
            v-for="stat of statArmada"
            :key="stat.icon"
          >
            <Stat :title="stat.title" :image="stat.image" :value="stat.value" />
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-md-6" v-for="stat of ArmadaAll" :key="stat.icon">
        <Stat :title="stat.title" :image="stat.image" :value="stat.value" />
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12">
        <div class="page-title-box p-0">
          <h4 class="font-size-18">Transaksi</h4>
        </div>
      </div>

      <div class="col-xl-12 mb-3">
        <b-button
          class="btn-trans"
          @click="filterButton('all')"
          :class="{ transActive: transactionType === 'all' }"
          >All</b-button
        >
        <b-button
          class="btn-trans ml-3"
          :class="{ transActive: transactionType === 'logistic_company' }"
          @click="filterButton('logistic_company')"
          >Logistic</b-button
        >
        <b-button
          class="btn-trans ml-3"
          :class="{ transActive: transactionType === 'truck_owner' }"
          @click="filterButton('truck_owner')"
          >Truck Owner</b-button
        >
        <b-button
          class="btn-trans ml-3"
          :class="{ transActive: transactionType === 'driver' }"
          @click="filterButton('driver')"
          >Truck Driver</b-button
        >
      </div>

      <div class="col-xl-12 mb-1">
        <h4 class="font-size-13 font-weight-bolder">
          <span class="mr-3">Filter Tanggal</span>
          <span
            @click="resetFilter()"
            style="cursor: pointer"
            class="text-primary"
            >Reset</span
          >
        </h4>
      </div>

      <div class="col-md-12">
        <div class="row ml-0">
          <ValidationObserver v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(filtering)">
              <div class="row">
                <div class="col-sm-5">
                  <ValidationProvider
                    name="Start Date"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <div class="form-group mb-3">
                      <date-picker
                        v-model="payloadDate.startDate"
                        format="YYYY-MM-DD"
                        lang="en"
                        type="month"
                        value-type="YYYY-MM-DD"
                        :disabled-date="notBeforeToday2"
                      ></date-picker>
                      <p class="fs-6 text-danger">
                        {{ errors[0] }}
                      </p>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-5">
                  <ValidationProvider
                    name="End Date"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <div class="form-group mb-3">
                      <date-picker
                        v-model="payloadDate.endDate"
                        format="YYYY-MM-DD"
                        type="month"
                        lang="en"
                        value-type="YYYY-MM-DD"
                        :disabled-date="notBeforeToday"
                      ></date-picker>
                      <p class="fs-6 text-danger">
                        {{ errors[0] }}
                      </p>
                    </div>
                  </ValidationProvider>
                </div>

                <div class="col-sm-2">
                  <b-button type="submit" variant="primary" class="px-5"
                    >Apply</b-button
                  >
                </div>
              </div>
            </b-form>
          </ValidationObserver>
        </div>
      </div>

      <div class="col-xl-4" :class="{ 'col-xl-6': transactionType === 'all' }">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-start align-items-center row">
              <div class="col-md-2">
                <img
                  src="@/assets/images/services-icon/chart-01.png"
                  alt
                  width="42.18px"
                  height="42"
                />
              </div>
              <div class="col-md-10">
                <h5
                  class="text-capitalize font-size-14 text-left text-dash-string pt-2 font-weight-bolder"
                >
                  Total Penawaran
                </h5>
                <h5
                  class="text-capitalize font-size-14 text-dash-number ml-1 mb-0 font-weight-bolder"
                >
                  {{ totalChartOffer }}
                </h5>
              </div>
            </div>
            <apexchart
              ref="transOffer"
              class="apex-charts"
              dir="ltr"
              height="380"
              type="line"
              :series="transOffer.seriesOffer"
              :options="transOffer.chartOptionsOffer"
            ></apexchart>
          </div>
        </div>
      </div>

      <div class="col-xl-4" :class="{ 'col-xl-6': transactionType === 'all' }">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-start align-items-center row">
              <div class="col-md-2">
                <img
                  src="@/assets/images/services-icon/armada.png"
                  alt
                  width="42.18px"
                  height="42"
                />
              </div>
              <div class="col-md-10">
                <h5
                  class="text-capitalize font-size-14 text-left text-dash-string pt-2 font-weight-bolder"
                >
                  Total Armada
                </h5>
                <h5
                  class="text-capitalize font-size-14 text-dash-number ml-1 mb-0 font-weight-bolder"
                >
                  {{ totalChartArmada }}
                </h5>
              </div>
            </div>
            <apexchart
              ref="transArmada"
              class="apex-charts"
              dir="ltr"
              height="380"
              type="line"
              :series="transArmada.seriesAramada"
              :options="transArmada.chartOptionsArmada"
            ></apexchart>
          </div>
        </div>
      </div>

      <div class="col-xl-4" v-if="transactionType !== 'all'">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-start align-items-center row">
              <div class="col-md-2">
                <img
                  src="@/assets/images/services-icon/chart-02.png"
                  alt
                  width="42.18px"
                  height="42"
                />
              </div>
              <div class="col-md-10">
                <h5
                  class="text-capitalize font-size-14 text-left text-dash-string pt-2 font-weight-bolder"
                >
                  Total
                  <span v-if="transactionType == 'logistic_company'"
                    >Pengeluaran</span
                  ><span v-else> Pendapatan </span>
                </h5>
                <h5
                  class="text-capitalize font-size-14 text-dash-number ml-1 mb-0 font-weight-bolder"
                >
                  {{ totalChartIncome | currency_3 }}
                </h5>
              </div>
            </div>
            <apexchart
              ref="transIncome"
              class="apex-charts"
              dir="ltr"
              height="380"
              type="line"
              :series="transIncome.seriesIncome"
              :options="transIncome.chartOptionsIncome"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
