<script>
/**
 * Widget-stat component -- specify the widget title, image, value, subtext and color of subtext.
 */
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    value: {
      type: Number,
      default: 0,
    },
    subText: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
  },
};
</script>

<template>
  <div class="card mini-stat bg-white">
    <div class="card-body">
      <div class="d-flex justify-content-start align-items-center row">
        <div class="col-md-2">
          <img :src="`${image}`" alt width="42.18px" height="42" />
        </div>
        <div class="col-md-7">
          <h5
            class="text-capitalize font-size-15 text-dash-string pt-2 font-weight-bolder"
          >
            {{ title }}
          </h5>
        </div>
        <div class="col-md-3">
          <h5
            class="text-capitalize text-center font-size-24 text-dash-number pt-1 font-weight-bolder"
          >
            {{ value }}
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>
